<template>
  <router-view/>
</template>

<script>
    export default {
    } 
</script>

<style>
#app{
  height: 100vh;
}
</style>
