
async function isAuthGuard(to, from, next){
  if (localStorage.getItem('token')) {
        next('/dashboard')
     }else{
       next();
     }
}

export default isAuthGuard
